import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SmallBanner = ({ data }) => {
  return (
    <div className="banner-small-wrap mt-4 h-auto md:h-96 rounded-none md:rounded-md overflow-hidden">
      {data.nodes.length === 0 ? (
        <StaticImage
          className="w-full"
          src="https://media.kubric.io/api/assetlib/ba3c9087-bc43-45c5-b495-302f005b73ad.png"
          alt="placeholder"
          placeholder="blurred"
        />
      ) : (
        data.nodes.map(card => (
          <div className="banner-img-wrap">
            <a
              target="_blank"
              href={card.meta.url !== null ? card.meta.url : "/"}
            >
              <img
                className="w-full h-full object-cover"
                src={card.url}
                alt="grid image"
              />
            </a>
          </div>
        ))
      )}
    </div>
  )
}

export default SmallBanner
