import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Banner = ({ data }) => {
  return (
    <div className="banner-wrap full-bleed bg-gray-200 overflow-hidden">
      {data.nodes.length === 0 ? (
        <StaticImage
          className="w-full"
          src="https://media.kubric.io/api/assetlib/ba3c9087-bc43-45c5-b495-302f005b73ad.png"
          alt="placeholder"
          placeholder="blurred"
        />
      ) : (
        data.nodes.map(banner => (
          <a
            key={banner?.meta?.url}
            target="_blank"
            href={banner?.meta?.url === null ? "/" : banner?.meta?.url}
          >
            <div className="img-wrap w-full h-full">
              <img
                className="w-full h-full object-cover"
                src={banner.url}
                alt="banner image"
              />
            </div>
          </a>
        ))
      )}
    </div>
  )
}

export default Banner
