import * as React from "react"
import Banner from "../components/Banner/Banner"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import GridA from "../components/GridA/GridA"
import GridB from "../components/GridB/GridB"
import GridC from "../components/GridC/GridC"
import SmallBanner from "../components/SmallBanner/SmallBanner"
import GridE from "../components/GridE/GridE"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title={"Garnet | Shoes"}/>
    <Banner data={data.banner} />
    <GridA slides={data.slides} products={data.products}/>
    <GridB data={data.featProd}/>
    <GridC small={data.small} medium={data.medium}/>
    <SmallBanner data={data.smallBanner}/>
    <GridE data={data.bestseller}/>
  </Layout>
)

export const query = graphql`
{
  banner: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "banner"}, position: {eq: "leaderboard"}}}
    sort: {fields: updated_time, order: DESC}
    limit: 1
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
  }
  slides: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "slide"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
  products: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "featured_products"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
  featProd: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "featured_product"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
  small: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "latest_products"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
  medium: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "latest_categories"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
  smallBanner: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "banner"}, position: {eq: "interstitial"}}}
    sort: {fields: updated_time, order: ASC}
    limit: 1
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
  }
  bestseller: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "bestseller"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
}
`


export default IndexPage
