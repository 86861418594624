import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import "./Slideshow.scss"

const Slideshow = ({ data }) => {
  const [index, setIndex] = useState(1)
  useEffect(() => {
    const autoplay = setTimeout(() => {
      index === data.nodes.length ? setIndex(1) : setIndex(index + 1)
    }, 2000)
    return () => clearTimeout(autoplay)
  }, [index])
  return (
    <div className="slider-wrap w-full h-full relative">
      <div className="controls">
        <div
          className="btn shadow-md prev"
          onClick={() => {
            index === 1 ? setIndex(data.nodes.length) : setIndex(index - 1)
          }}
        >
          <ChevronLeft />
        </div>
        <div
          className="btn shadow-md next"
          onClick={() => {
            index === data.nodes.length ? setIndex(1) : setIndex(index + 1)
          }}
        >
          <ChevronRight />
        </div>
      </div>
      {data.nodes.length === 0
        ? Array.apply(null, Array(3)).map((item, i) => (
            <div className="grid-img-wrap small-grid-card">
              <StaticImage
                className="w-full h-full object-cover"
                src="https://media.kubric.io/api/assetlib/48bf4495-c031-4171-9eea-20a44f2e02b4.png"
                alt="product image"
              />
            </div>
          ))
        : data.distinct.map(position => {
            let card = data.nodes.filter(
              elem => elem.meta.position === position
            )[0]
            return (
              <a
                className={`slider-card-wrap block ${
                  index === parseInt(position) ? "slider-card-active" : "hidden"
                }`}
                target="_blank"
                href={card?.url ? card?.url : "/"}
              >
                <img
                  className="w-full h-full object-cover"
                  src={card?.url}
                  alt="slide image"
                />
              </a>
            )
          })}
    </div>
  )
}

export default Slideshow
